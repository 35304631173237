.DescriptionCard {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
}

.DescriptionCard:after {
    box-sizing: border-box;
    content: ' ';
    display: block;
    height: 0px;
    border: 10px solid #003E68;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
}