.Box {
	background-color: #fff;
	/*border-radius: 30px;*/
	color: #003e68;
}

.Box--padded {
	padding: 30px;
}

.Box--blue {
	background-color: #003e68;
	color: #fff;
}

.Box--green {
	background-color: green;
	color: #fff;
}

.Box--bordered {
	border: 5px solid #003e68;
}

.Box--rounded {
	border-radius: 20px;
}

.Box--pricing {
	background-color: red;
}