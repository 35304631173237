.Modal.ContactFormModal {
    min-width: 400px;
    width: fit-content
}

/*.Modal.ContactFormModal main {*/
/*    display: flex;*/
/*}*/

/*.ContactFormModal__image {*/
/*    background-color: #ccc;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    width: 40%;*/
/*}*/

/*.ContactFormModal__form {*/
/*    padding: 20px;*/
/*    padding-left: 100px;*/
/*}*/