.ServiceCard2 {
    background-color: #fff;
    min-width: 100px;
    min-height: 200px;
    overflow: hidden;
    padding: 20px;
    position: relative;
}

@media only screen and (max-width: 600px) {
        .ServiceCard2 {
            margin: 20px 0;
        }
}

.ServiceCard2__image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-width: 100px;
    min-height: 200px;
    position: relative;
}

.ServiceCard2__info {
}

.ServiceCard2__info h4 {
    background-color: #8dc63f;
    display: inline-block;
    margin: 0;
    font-weight: bold;
    padding: 5px 20px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 250ms linear;
}

.ServiceCard2:hover .ServiceCard2__info h4 {
    background-color: #fff;
    /*transform: scale(1.2);*/
}

.ServiceCard2 main {
    background-color: #fff;
    background-blend-mode: color-burn;
    bottom: -100px;
    color: #666;
    visibility: hidden;
    padding: 10px 20px;
    position: absolute;
    transition: all 500ms ease;
}

.ServiceCard2:hover main {
    bottom: 0;
    visibility: visible;
}