.AgentServiceCards {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 20px;
}

@media only screen and (max-width: 1024px) {
    .AgentServiceCards {
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 600px) {
    .AgentServiceCards {
        display: block;
    }
}