.CircleIcon {
    display: inline-block;
}

.CircleIcon i {
    font-size: 30px;
    background-color: #003e68;
    border-radius: 50%;
    color: #fff;
    line-height: 40px;
    width: 40px;
    height: 40px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
}