.YesNoQuestion {
	text-align: center;
}

.YesNoQuestion main {
	margin: 20px 0;
}

.YesNoQuestion i {
	font-size: 30px;
	background-color: #003e68;
	border-radius: 50%;
	color: #fff;
	line-height: 40px;
	width: 40px;
	height: 40px;
	padding: 10px;
	vertical-align: middle;
	text-align: center;
}

.YesNoQuestion__pre-text {
	font-size: 16px;
	line-height: 1em;
	margin-bottom: 5px;
}

.YesNoQuestion__question {
	font-size: 25px;
	font-weight: bold;
	line-height: 1em;
}