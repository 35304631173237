.ValuePage__values {
    background-color: #003e68;
    padding: 50px 0;
}

.ValuesPage__benefits {
    justify-content: center;
}

.ValuePage__benefit-card-front {
    background-color: #8dc63f;
    display: flex;
    min-height: 200px;
    padding: 30px 10px;
    box-sizing: border-box;
    border: 1px solid #003e68;
    justify-content: center;
    align-items: center;
}

.ValuePage__benefit-card-front i {
    color: #fff;
    font-size: 50px;
}

.ValuePage__benefit-card-front main {
    text-align: center;
}

.ValuePage__benefit-card-front main div {
    color: #fff;
    margin-top: 20px;
}

.ValuePage__benefit-card-back {
    /*background-color: #fff;*/
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);*/
    box-sizing: border-box;
    color: #000;
    /*margin-top: -20px;*/
    /*min-height: 200px;*/
    /*padding: 20px;*/
    /*pointer-events: none;*/
}

.ValuePage__benefit-card-back header {
    color: #8dc63f;
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

/*.Modal.ValuePage__benefit-modal {*/
/*    background: none;*/
/*    outline: 1px solid red;*/
/*    margin: 0 auto;*/
/*    !*width: 400px;*!*/
/*    !*overflow: auto;*!*/
/*    height: 100vh;*/
/*    box-sizing: border-box;*/
/*}*/
/*.Value style={{color: '#fff', fontSize: 50}}/><br/>*/
/*<div style={{color: '#fff', marginTop: 20}}>{title}</div>*/