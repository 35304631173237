.Button {
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
}

.Button.link {
    text-decoration: underline;
    text-decoration-color: #ccc;
}

.Button.link.block {
    display: block;
}

.Button.link:hover {
    text-decoration-color: #003e68;
}

.Button.normal, .Button.primary, .Button.secondary, .Button.danger {
    padding: 10px 20px;
}

.Button.normal {
    background-color: #ccc;
}

.Button.primary {
    background-color: #003e68;
    color: #fff;
}

.Button.primary.inverted {
    background-color: #fff;
    color: #003e68;
}

.Button.primary.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    font-style: italic;
}

.Button.secondary {
    background-color: #8dc63f;
    color: #fff;
}