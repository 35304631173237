.YesNoButtons {
	border: 1px solid rgb(62, 63, 102);
	border-radius: 3px;
	display: inline-flex;
	position: relative;
}

.YesNoButtons__button {
	box-sizing: border-box;
	color: white;
	cursor: pointer;
	/*display: inline-block;*/
	min-width: 150px;
	padding: 10px;
}

.YesNoButtons__button--yes {
	background-color: rgb(102, 204, 102);
}

.YesNoButtons__button--yes:hover {
	/*box-shadow: inset 0 0 10px #0f0;*/
	background-color: #003e68;
}

.YesNoButtons__button--no {
	background-color: rgb(68, 68, 68);
}

.YesNoButtons__button--no:hover {
	/*box-shadow: inset 0 0 10px #ccc;*/
}

.YesNoButtons__button--no:hover {
	background-color: #c00;
}

.YesNoButtons__button, .YesNoButtons__or {
	text-align: center;
}

.YesNoButtons__or {
	background-color: #fafaf8;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	z-index: 1;
	box-shadow: 0 0 0 6px rgba(255, 255, 255, .2);
}