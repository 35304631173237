.PageFooter {
    background-color: #003e68;
	color: rgba(255, 255, 255, 0.5);
	margin-top: 50px;
	padding: 40px;
}

/*.PageFooter main {*/
/*	display: flex;*/
/*	gap: 40px;*/
/*}*/
/**/
.PageFooter .address {
	/*margin-top: 30px;*/
	/*color: #fff;*/
	/*font-weight: bold;*/
	/*font-size: 1.2rem;*/
}

.FooterFooter__logo {
	width: 250px;
}

.PageFooter h4 {
	margin-top: 0;
}