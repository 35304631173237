.PageHeader {
    display: flex;
}

@media only screen and (max-width: 800px) {
    .PageHeader {
        /*display: block;*/
        /*text-align: center;*/
    }
}

.PageHeader > div {
    flex-grow: 1;
}

.PageHeader > div:last-child {
    /*text-align: right;*/
}

@media screen and (max-width: 800px) {
    .PageHeader > div:last-child {
        text-align: center;
    }
}

.PageHeader__logo {
    width: 250px;
}

.PageHeader__nav {
    background-color: #003e68;
    padding: 10px;
    color: #fff;
}

.PageHeader__nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.PageHeader__nav li {
    margin-bottom: 5px;
    text-transform: uppercase;
    padding: 0;
}

.PageHeader__nav li:last-child {
    margin-bottom: 0;
}

.PageHeader__phone {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: #003e68;
}

@media screen and (max-width: 800px) {
    .PageHeader__phone {
        display: block;
        text-align: center;
    }
}