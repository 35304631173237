.Checkbox {
    border: 1px solid #007bff;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    vertical-align: center;
    text-align: center;
    padding: 2px;
    height: 24px;
    width: 24px;
}

.Checkbox.checked {
    background-color: #007bff;
    color: #fff;
}

.Checkbox.disabled {
    cursor: unset;
    /*background-color: #c00!important;*/
    /*color: #cc0!important;*/
}