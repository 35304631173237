.Padding {
    padding: 50px;
}

.large.Padding {
    padding: 100px;
}

.small.Padding {
    padding: 20px;
}

