table.PriceChart {
    border-collapse: collapse;
    border: 2px solid #000;
    font-size: 1.4em;
    max-width: 800px;
    margin: 0 auto;
}

.PriceChart thead {
    background-color: #003e68;
    color: white;
}

.PriceChart thead th {
    padding: 10px;
}

.PriceChart tbody {
    background-color: #fff;
}

.PriceChart tbody tr.section {
    background-color: #e1e1e1;
}

.PriceChart tbody th, .PriceChart tbody td {
    text-align: left;
    padding: 5px;
    border: 1px solid #003e68;
}

.PriceChart tbody tr.indent th {
    padding-left: 50px;
}

.PriceChart tbody th em {
    font-weight: normal;
}

.PriceChart tbody td {
    text-align: right;
}
