.PricesPage__service-price {
    /*display: flex;*/
    /*flex-direction: column;*/
    margin: 10px;
    position: relative;;
    text-align: center;
}

.PricesPage__service-price main {
    background-color: #fff;
    border: 1px solid #003e68;
    border-radius: 20px;
    padding: 20px;

    justify-content: flex-end;
    align-content: flex-end;
}

.PricesPage__service-price.inverted main {
    background-color: #003e68;
    color: #fff;
}

.PricesPage__service-price__qualifier {
    /*background-color: #9d9;*/
    /*border-top-left-radius: 20px;*/
    /*border-bottom-right-radius: 20px;*/
    border-top: none;
    /*color: white;*/
    font-size: 0.9rem;
    line-height: 1em;
    /*padding: 3px 20px;*/
    /*position: absolute;*/
    /*top: 1px;*/
    /*left: 50%;*/
    text-transform: uppercase;
    /*transform: translateX(-50%);*/
}

.PricesPage__service-price__title {
    font-size: 1.4em;
    font-weight: bold;
}

.PricesPage__service-price__price {
    font-size: 3em;
    font-weight: bold;
}

.PricesPage__service-price__description {

}

.PricesPage__hero-image {
    max-width: 800px;
    margin: 0 auto;
}

.PricesPage__hero-image main {
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;
    max-width: 800px;
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 40%;
}
