.AboutPage__our-story {
    /*background-color: #8dc63f;*/
    background-image: url(../../../public/assets/img/AdobeStock_103801442.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 500px 500px rgba(0, 0, 0, 0.8);
    color: #fff;
    /*min-height: 500px;*/
    padding: 50px;
}