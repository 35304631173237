.TextInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
}

.TextInput.error {
    background-color: #fee;
    border-color: red;
    color: red;
}