.CheckboxInput {
    gap: 20px;
}

.CheckboxInput__column {
    flex-shrink: 1;
    /*flex-grow: 1;*/
}

.CheckboxInput__row {
    margin-bottom: 10px;
}

.CheckboxInput__row:last-child {
    margin-bottom: 0;
}