.Modals-overlay {
    position: fixed;
    color: #fff;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: auto;
}
