.CheckmarkBullet {
    display: flex;
    gap: 10px;
}

.CheckmarkBullet__check {

}

.CheckmarkBullet__check i {
    color: #003e68;
    display: inline-block;
}

.CheckmarkBullet--secondary .CheckmarkBullet__check i {
    color: #8dc63f;
}