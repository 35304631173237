.HomeHeroContainer2 {
    /*background-color: #fff;*/
    background-color: #000;
    background-image: url(../../public/assets/img/AdobeStock_103801442.jpg);
    background-position: center 20%;
    background-size: cover;
    /*opacity: 0.4;*/
    /*background-color: #003e68;*/
    margin: 20px 0;
    padding: 50px 50px 100px;
    /*position: relative;*/
    box-shadow: inset 0px 0px 1000px 1000px rgba(0, 0, 0, 0.6);
    /*z-index: -1;*/
}

.HomeHeroContainer2 .hero {
    color: #fff;
    /*position: relative;*/
    /*z-index: 1;*/
}

.HomeHeroContainer2 .hero .row {
    align-items: center;
}

.HomeHeroContainer2 h1 {
    text-shadow: 0 0 10px #000;
    font-size: 45px;
    line-height: 1em;
    margin: 0;
    padding-bottom: 10px;
    text-align: center;
}

.HomeHeroContainer2 main {
    /*text-shadow: 0 0 10px #000;*/
}

.HomeHeroContainer2 h1 small {
    /*color: #003e68;*/
    display: block;
    font-size: 0.66em;
    /*font-size: 0.4em;*/
    /*line-height: 1em;*/
    font-weight: normal;
}

.Home__pricing {
    color: #8dc63f;
    /*font-weight: bold;*/
    /*background-color: rgba(141, 198, 63, 0.2);*/
    /*background-color: rgba(0, 0, 0, 0.5);*/
    background-color: #8dc63f;
    border-radius: 50px;
    font-size: 1.4em;
    margin: 20px 0 30px;
    /*padding: 20px;*/
    text-align: center;
}

.Home__pricing .Button {
    padding: 20px;
    color: #fff;
}

.connect-vert-mid {
    border-right: 1px solid #ccc;
}

.or-circle {
    background-color: #003e68;
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    left: 50%;
    height: 50px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
}

.connect-2vert-down {
    margin: 0 auto;
    border: 1px solid #ccc;
    border-bottom: none;
}

.connect-2vert-up {
    margin: 0 auto;
    border: 1px solid #ccc;
    border-top: none;
}

@media only screen and (max-width: 640px) {
    .HomeHeroContainer2 {
        padding: 10px;
    }
    .HomeHeroContainer2 h1 {
        font-size: 30px;
        margin: 0;
    }
}

.HomeHeroContainer2__map-txt {
    color: #aaa;
    font-size: .7em;
    line-height: .8em;
}

.HomeHero {
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    padding: 30px;
}

.HomeHero > main {
}

.HomeHero .img {
    display: inline-block;
    margin-right: 10px;
    max-width: 220px;
    width: 100%;
}

.HomeHero img {
    width: 100%;
}

/*.CheckList ul {*/
/*    list-style: none;*/
/*    margin-left: 0;*/
/*    padding: 0;*/
/*}*/

/*.CheckList ul ul {*/
/*    padding-left: 50px;*/
/*}*/

/*.CheckList li {*/
/*    margin: 10px 0;*/
/*}*/

.HomePage h2 {
    text-align: center;
}

.Container.section.choices {
    margin-top: -100px;
}

@media screen and (max-width: 800px) {
    .Container.section.choices {
        margin-top: unset;
    }
}