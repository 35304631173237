.Container {
    max-width: 1024px;
	/*padding: 0 20px;*/
    margin: 0 auto;
}

.Container.section {
	margin-top: 50px;
	margin-bottom: 50px;
    outline-color: #00c;
}

.Container--narrow {
    max-width: 600px;
    margin: 0 auto;
}

.Container.safe-sides {
    padding-left: 20px;
    padding-right: 20px;
}